import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/10c54834c88f3a1fbce1c21dd0178a17/588dc/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.66666666666669%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB9eIbBJmSdXIv/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAEQEQISQSH/2gAIAQEAAQUCyplJR0R2Njb2z//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ASn/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwEp/8QAGBAAAgMAAAAAAAAAAAAAAAAAADEQITD/2gAIAQEABj8CsWCFH//EAB8QAAICAgEFAAAAAAAAAAAAAAABETEhQRBRYXGRsf/aAAgBAQABPyGhLHQTEpvfDt54oO0LZlaYmNL37kMPoT5P/9oADAMBAAIAAwAAABCAA3L/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQMBAT8QyFf/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/EMgqP//EAB0QAAICAgMBAAAAAAAAAAAAAAERACExQWGBkbH/2gAIAQEAAT8QJoEPbMuSJOBYBSejCZ0AWsCdYwx7AQ0uIGwuYifKEIsLgxsIZWoNP3jPF3U//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/10c54834c88f3a1fbce1c21dd0178a17/e5166/01.jpg",
              "srcSet": ["/static/10c54834c88f3a1fbce1c21dd0178a17/f93b5/01.jpg 300w", "/static/10c54834c88f3a1fbce1c21dd0178a17/b4294/01.jpg 600w", "/static/10c54834c88f3a1fbce1c21dd0178a17/e5166/01.jpg 1200w", "/static/10c54834c88f3a1fbce1c21dd0178a17/588dc/01.jpg 1468w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      